@import "../../Constants/colors";

.notificationDot {
  position: fixed;
  top: 25px;
  left: 29px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #E02130;
  box-shadow: 0 0 0 5px rgba(#E02130, 0.2);
  opacity: 0;
  transition: opacity 200ms;
  z-index: 10;
}

.notificationDotShow {
  opacity: 1;
}

.background {
  position: relative;
  display: block;
  background-color: #f2f2f1;
}

.base {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.backgroundCircle {
  position: absolute;
  top: -50vw;
  left: -50vw;
  width: 100vw;
  height: 100vw;
  border-radius: 50%;
  background: radial-gradient(#f6f6f5, #f2f2f1);
  z-index: 0;
  pointer-events: none;
}

.wrap {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 56px 160px 80px;
  background-color: #fff;
}

.header {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.headerTitle {
  flex: 1;
  position: relative;
  display: block;
}

.billNumber {
  position: relative;
  display: block;
  margin: 0;
  font-size: 44px;
  line-height: 56px;
  letter-spacing: -1.1px;
  color: $fontBlack;
}

.date {
  position: relative;
  display: block;
  margin-bottom: 18px;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgba(0,0,0,.5);
}

.sellerWrap {
  position: relative;
  display: block;
  text-align: right;
  padding-bottom: 8px;
}

.sellerTitle {
  position: relative;
  display: block;
  margin-bottom: 12px;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #00a99a;
}

.seller {
  position: relative;
  display: block;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 20px;
  color: $fontBlack;
}

.content {
  flex: 1;
  position: relative;
  display: flex;
}

.contentTable {
  flex: 1;
  padding-right: 50px;
  border-right: 1px solid rgba(0,0,0,0.1);
}

.contentBill {
  padding-left: 50px;
  min-width: 234px;
}

.footer {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid rgba(0,0,0,0.1);
}

.copyright {
  position: relative;
  display: block;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 24px;
  color: $fontBlack;

  @media only screen and (max-width: 900px) {
    margin-right: 5px;
  }
}

.copyrightImage {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 23px;
  margin-bottom: 3px;
  margin-right: 6px;
}

.offer {
  position: relative;
  display: block;
  font-size: 16px;
  color: rgba($tomeBlack, 0.56);
}

.modalTextBold {
  font-weight: 500;
}
