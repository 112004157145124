@import "src/Constants/colors";

.icon {
  width: 291px;
  height: 183px;
}

.box {
  max-width: 612px;
  width: 100%;
  margin: 0 auto;
}
