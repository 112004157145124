@import "../Constants/colors.scss";

.ant-modal-custom {
  max-width: 450px;
  padding-top: 54px;
  transform-origin: 106px 416px !important;

  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      margin: 0 0 16px;
    }
    .ant-modal-confirm-content {
      color: rgba($tomeBlack, 0.56);
    }
  }
}

.ant-modal-custom-ok,
.ant-modal-custom-cancel {
  height: 38px;
  padding: 0 20px;
  border: 0;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: all 200ms !important;
  text-shadow: none;
}

.ant-modal-custom-ok {
  margin-left: 16px !important;
  background-image: linear-gradient(135deg, #ffe877, #ffec78) !important;
  background-color: $tomeYellow !important;
  color: $fontBlack !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), 0 2px 1px rgba(0,0,0, 0.03) !important;

  &:hover {
    background-color: rgba($tomeYellow, 0.9) !important;
  }
  &:active {
    background-image: linear-gradient(-45deg, #fbe05e, #f5de5c) !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), 0 2px 1px rgba(0,0,0, 0) !important;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }
}

.ant-modal-custom-cancel {
  color: $fontBlack !important;
  background-color: $white !important;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.16), 0 2px 1px rgba(0,0,0, 0.03) !important;

  &:hover {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0,0,0, 0.03) !important;
  }

  &:active {
    background-color: rgba($tomeBlack, 0.02) !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.16) !important;
  }

  &[click-animating-without-extra-node]:after {
    border-color: rgba($tomeBlack, 0.2) !important;
  }
}
