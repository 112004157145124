@import "../../Constants/colors";

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  padding-left: 24px;
  justify-content: space-between;
  background-color: #dffbf5;
  z-index: 10000;
}

.billNumber {
  position: relative;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: $fontBlack;
}

.logoWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 54px;
  padding: 0;
  border: 0;
  background: transparent;

  &:focus { outline: none }
}

.logoDash {
  position: absolute;
  left: 0;
  top: -4px;
}


.content {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 32px 24px 32px;
  margin-bottom: 9px;
  background-color: #fff;
}

.seller {
  position: relative;
  display: block;
  margin: 0 0 14px;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  color: $fontBlack;
}

.description {
  position: relative;
  display: block;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba($tomeBlack, 0.56);
}

.productSection {
  position: relative;
  display: block;
  margin-bottom: 9px;
}

.product {
  position: relative;
  display: block;
  margin-bottom: 9px;
  padding: 0 24px;
  background-color: #fbfbfb;
}

.productRow {
  position: relative;
  display: flex;
  align-items: center;

  .productLine {
    border-bottom: none;
    padding-bottom: 6px;

    &:last-child {
      padding-left: 24px;
      border-left: 1px solid rgba($tomeBlack, 0.1);
    }
  }
  .productTitle {
    padding-top: 8px;
  }
}

.productLine {
  flex: 1;
  position: relative;
  display: block;
  border-bottom: 1px solid rgba($tomeBlack, 0.1);
}

.productTitle {
  position: relative;
  display: block;
  padding-top: 16px;
  font-size: 12px;
  line-height: 15px;
  color: $tomeBlack;
  pointer-events: none;
}

.productText {
  position: relative;
  display: block;
  margin: 11px 0;
  padding: 0;
  border: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: $fontBlack;
  resize: none;
}

.currency {
  font-family: 'Roboto Extd', serif;
}

.footer {
  position: relative;
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 24px;
}

.createBill {
  padding: 0 40px;
}

.footerOffer {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 24px;
}

.offerWrap {
  border-top: 1px solid rgba($tomeBlack, 0.1);
}

.offerText {
  text-align: left;
  font-size: 16px;
  color: rgba($fontBlack, 0.56);
}

.offer {
  display: inline-block;
  padding: 0 5px;
  border-radius: 3px;
  background: rgba(186, 186, 186, 0.18);
  color: rgba($fontBlack, 0.56);

  &:hover {
    color: $tomeRed;
  }
}
