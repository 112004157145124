@import "src/Constants/colors";

.wrap {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  background: #fff;

  @media only screen and (max-width: 1000px) {
    min-height: calc(100vh - 64px);
  }
}

.header {
  position: relative;
  display: block;
  overflow: hidden;
  height: 185px;
  width: 100%;
  background-color: #fdfdfd;
}

.headerWave {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.headerImg {
  position: absolute;
  top: 33px;
  left: -20px;
  width: 392px;
  height: 204px;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 42px 0 56px;
}

.inviteLink {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 16px;
  font-size: 16px;
  color: $fontBlack;
}

.copyButton {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  padding: 0 20px;
}
