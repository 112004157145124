@import "src/Constants/colors";

.wrap {
  position: relative;
  display: block;
}

.sumWrap {
  position: relative;
  display: block;
  text-align: right;
}

.sumTitle {
  position: relative;
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: rgba($tomeBlack, 0.56);
}

.sum {
  position: relative;
  display: block;
  font-size: 36px;
  color: $fontBlack;
}

.sumBold {
  font-weight: 500;
}

.createBill {
  padding: 0 40px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
}

.bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid rgba(#000, 0.1);
}

.bottomSucceeded {
  border-top: none;
  padding-top: 0;
}

.bottomSucceededIco {
  position: relative;
  display: block;
  width: 184px;
}

.bottomIcon {
  position: relative;
  display: block;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}
