$pointGreen: #009323;
$tomeYellow: #FEE86F;
$tomeBlack: #121413;
$fontBlack: #000;
$white: #fff;
$whiteLightGray: #FDFDFD;
$whiteGray: #FCFCFC;
$tomeWhiteBG: #f8f8f8;
$black: #000104;
$blue: #0054B9;
$tomeRed: #E02130;
$warningRed: #ED2333;
$lightGray: #C8C8C8;
$gray: #B7B8B8;
$linkBlue: #71B9F9;
$inputYellow: #FFD763;
