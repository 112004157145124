/* Make clicks pass-through */
#nprogressWrapper {
  #nprogress,
  #nprogress .bar {
    top: 0;
  }
}

#nprogress {
  pointer-events: none;
  position: fixed;
  z-index: 9;
  top: 64px;
  left: 0;

  width: 100%;
  height: 1px;
  background-color: transparent;
}

#nprogress .bar {
  background-color: #ED2333;

  position: fixed;
  z-index: 9;
  top: 64px;
  left: 0;

  width: 100%;
  height: 1px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  //box-shadow: 0 0 10px #fcd531, 0 0 5px #fcad27;
  opacity: 1;
  z-index: -10;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
