@import "src/Constants/colors";

.wrap {
  position: relative;
  display: block;
  width: 100%;
  padding: 52px 96px 56px;
  border-radius: 4px;
  background: #fff;
}

.button {
  display: block;
  width: auto;
  padding: 0 20px;
  margin: 0 auto;
}

.title {
  position: relative;
  display: block;
  margin: 0 0 30px;
  text-align: center;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.42;
  color: $fontBlack;
}

.itemsWrap {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  margin-bottom: 34px;
}

.item {
  position: relative;
  display: block;
  text-align: center;
}

.itemFirst {
  .itemIcon {
    font-size: 26px;
  }
  .itemText {
    margin-top: 3px;
  }
}

.itemSecond {
  .itemIcon {
    font-size: 28px;
  }
}

.itemThird {
  .itemIcon {
    font-size: 31px;
  }
  .itemText {
    margin-top: -4px;
  }
}

.itemIcon {
  color: #000;
}

.itemText {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: rgba($tomeBlack, 0.56);
}

.flagListWrap {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  overflow: hidden;
  width: 100%;
  margin-top: 25px;
  padding: 32px 44px 34px 64px;
  border-radius: 4px;
  background: #fff;
}

.flagListItem {
  position: relative;
  display: flex;
  align-items: flex-start;
  font-size: 24px;
}

.flagListName {
  position: relative;
  display: block;
  margin-top: 7px;
  margin-left: 12px;
  font-size: 14px;
  color: $fontBlack;
}
