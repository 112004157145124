@import "src/Constants/colors";

.wrap {
  position: relative;
  display: block;
}

.table {
  display: grid;
  grid-template-columns: 1fr auto auto;
  margin-top: 34px;
  margin-bottom: 24px;
  column-gap: 28px;
  color: $fontBlack;
}

.header {
  font-size: 16px;
  color: rgba($tomeBlack, 0.56);

  &:nth-child(3n),
  &:nth-child(3n - 1) {
    text-align: right;
  }
}

.separator {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  margin: 18px 0 24px;
  grid-column: 1 / 4;
}

.separatorLast {
  margin-top: 6px;
}

.emptySeparator {
  margin: 8px 0;
  grid-column: 1 / 4;
}

.content {
  font-size: 16px;
  color: $fontBlack;
}

.contentRight {
  text-align: right;
}

.count {
  width: 80px;
}

.productAmount {
  position: relative;
  display: block;
}

.productAmountIco {
  position: absolute;
  right: 9px;
  top: 50%;
  margin-top: -7px;
}

.addButton {
  grid-column: 1 / 4;
  height: 80px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.commissionTitle,
.sumTitle {
  grid-column: 1 / 3;
  text-align: right;
}

.commissionTitle {
  font-size: 16px;
  color: rgba($tomeBlack, 0.56);
}

.sumTitle {
  font-weight: 500;
  font-size: 16px;
  color: $fontBlack;
}

.amount {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  font-size: 16px;
}

.rubIco {
  margin-top: -2px;
  margin-left: 9px;
}
