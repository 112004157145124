@import "../Constants/colors.scss";

$tooltipBgColor: rgba(#000, 0.8);

.ant-message {
  z-index: 0;
}

.ant-message-notice {
  width: 330px;
  padding: 8px 15px;
  text-align: left;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  animation-name: MessageOut;
}

@keyframes MessageOut {
  0% {
    max-height: 150px;
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    max-height: 0;
    transform: translateY(-8px);
    opacity: 0;
  }
}

.ant-message-notice-content {
  position: relative;
  max-width: 300px;
  min-height: 26px;
  padding: 8px 12px 10px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 3px;
  background: $tooltipBgColor;
  box-shadow: 0 -2px 8px 0 rgba(#000, 0.15), inner 0 0 0 1px rgba(#000, 0.16);
  color: #fff;

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    left: 10px;
    width: 16px;
    height: 7px;
    background-image: url('img/tooltipArrow/bottom.svg');
  }
}
