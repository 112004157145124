@import "../Constants/colors.scss";

@-webkit-keyframes autofill {
  to {
    background: #fff;
    color: $fontBlack;
  }
}

form textarea.ant-input {
  margin-bottom: 0;
  resize: none;
}


.ant-form-item-control {
  line-height: 34px;
}

.ant-input,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-input-number-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  border-color: rgba($tomeBlack, 0.16);
  border-radius: 4px;
  color: $fontBlack;

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }
}

.ant-input[disabled],
.ant-input-number-input[disabled],
.ant-input-number-input[disabled]::-webkit-input-placeholder, {
  color: rgba($fontBlack, 0.25);
}

.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active,
.ant-input-number-input:-webkit-autofill,
.ant-input-number-input:-webkit-autofill:hover,
.ant-input-number-input:-webkit-autofill:focus,
.ant-input-number-input:-webkit-autofill:active {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.ant-input::-webkit-input-placeholder,
.ant-select-selection::-webkit-input-placeholder,
.ant-input-number::-webkit-input-placeholder {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: rgba($tomeBlack, 0.5);
}

.ant-input[disabled]::-webkit-input-placeholder,
.ant-select-selection[disabled]::-webkit-input-placeholder {
  color: rgba($fontBlack, 0.25);
}

.ant-input,
.ant-input-number .ant-input-number-input {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.ant-input-sm,
.ant-input-number-sm .ant-input-number-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and (max-width: 1000px) {
    height: 48px;
    font-size: 15px;
  }
}

.ant-input-number-sm {
  height: 34px;
  @media only screen and (max-width: 1000px) {
    height: 48px;
  }
}

.ant-input-lg,
.ant-input-number-lg .ant-input-number-input {
  height: 44px;
  font-size: 19px;
  padding-left: 10px;
  padding-right: 10px;
}
.ant-input-number-lg { height: 44px }

.short-input .ant-form-item-children-icon { // использовать на FormItem
  display: none !important;
}

.ant-input:hover,
.ant-input-number:hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $inputYellow;
}

.ant-input-affix-wrapper {
  &:before {
    display: none;
  }
  .ant-input:not(:last-child) {
    padding-right: 25px;
  }
  .ant-input-lg:not(:last-child) {
    padding-right: 35px;
  }
  .ant-input-suffix {
    left: auto !important;
  }
}

.ant-input-affix-wrapper-sm {
  padding: 0;
  .ant-input-sm, .ant-input-number-sm .ant-input-number-input {
    height: 32px;
  }
}

.ant-input:focus,
.ant-input-number-focused,
.ant-input-affix-wrapper-focused,
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: rgba(239, 202, 0, 0.78);
  box-shadow: inset 0 1px 1px 1px #f9f9f8, 0 0 0 3px rgba(249, 207, 0, 0.43);
}

.ant-form-item {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0;
}

.ant-form-item-children {
  display: block;
}

.ant-form-item-children-icon {
  display: none;
}

.has-success.has-feedback .ant-form-item-children:after {
  content: '';
  position: absolute;
  top: 20px;
  right: 16px;
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  background: $pointGreen;
  margin: -3.5px 0 0;
}

.ant-form-item-has-error {
  * {
    color: $warningRed;
  }

  &.ant-input-affix-wrapper {
    > input.ant-input {
      padding-right: 11px;
      border-color: $warningRed;
      color: $warningRed;
      &:focus {
        box-shadow: inset 0 1px 1px 0.5px #f8f8f7, 0 0 0 3px rgba($warningRed, 0.2) !important;
      }
    }
  }

  .ant-input:not(.ant-input-disabled) {
    padding-right: 11px;
    border-color: $warningRed !important;
    color: $warningRed !important;

    &:focus {
      box-shadow: inset 0 1px 1px 0.5px #f8f8f7, 0 0 0 3px rgba($warningRed, 0.2) !important;
    }
  }

  .ant-input::-webkit-input-placeholder,
  .ant-select-selection__placeholder {
    color: $warningRed !important;
  }

  &.ant-form-item-has-feedback {
    .ant-form-item-children:after {
      content: '';
    }
    .ant-form-item-children-icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      top: 1px;
      bottom: 1px;
      right: 1px;
      margin-top: 0;
      height: auto;
      border-radius: 4px;
      background-color: #fff;
      z-index: 3;
      animation: none;

      &:before {
        content: '';
        position: relative;
        display: block;
        width: 6px;
        height: 6px;
        margin-right: 13px;
        border-radius: 3px;
        background-color: $tomeRed;
      }
    }
  }
}

.ant-input-group-addon {
  width: 40px;
  background: #fff;
}

.operation_refund_input {
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 45px;
  }
}

.card-csc-input {
  .has-error.has-feedback {
    .ant-input:not(.ant-input-disabled) {
      padding-right: 20px;
    }
    .ant-form-item-children-icon {
      width: 20px;

      &:before {
        margin-right: 9px;
      }
    }
  }
}

.phone-prefix {
  .ant-input-prefix {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    align-items: center;
    width: 40px;
    height: 22px;
    margin-top: -11px;
    text-align: center;
    line-height: 22px;
    z-index: 1;
  }
}

.product {
  min-width: 100%;
  height: 40px;
  border-radius: 4px;
  border: none;
  background-color: #F8F8F8;

  &:hover,
  &:focus {
    background-color: #FDFAD1;
    box-shadow: none;
  }
}

.product_right {
  text-align: right;
}

.product_amount {
  padding-right: 26px;
}

.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: none !important;
}

.ant-input-number-handler-up,
.ant-input-number-handler-up:active {
  background-image: url('img/inputNumberArrows/up.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.ant-input-number-handler-down,
.ant-input-number-handler-down:active {
  background-image: url('img/inputNumberArrows/down.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.ant-input-number-handler-up:active,
.ant-input-number-handler-down:active {
  background-color: rgba($tomeBlack, 0.02);
}
