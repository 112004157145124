@import "src/Constants/colors";

.ant-selection {
  height: 40px;
}

.ant-select {
  height: 40px;
  border: 1px solid rgba($tomeBlack, 0.16);
  border-radius: 4px;

  &.ant-select-open {
    border-color: rgba(0, 1, 4, 0.2);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ant-select-selector {
    .ant-select-selection-item {
      opacity: 1;
      line-height: 38px;
    }
  }

  &.ant-select-show-arrow {
    .ant-select-selection-item {
      padding: 0;
    }
  }
}

.ant-selection-small {
  height: 34px;

  @media only screen and (max-width: 1000px) {
    height: 48px;
  }
}

.ant-select-selector {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  min-height: 38px;
  padding: 0 24px 0 10px !important;
  border: none !important;
  border-radius: 0 !important;
  border-color: rgba($tomeBlack, 0.16);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: $fontBlack;
  background: none !important;
  box-shadow: none !important;

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }
}

.ant-select-multiple {
  overflow: hidden;

  .ant-select-selector {
    min-width: 1000px;
  }

  .ant-select-selection-search {
    position: absolute;
    top: 7px;
    left: 0;
    margin: 0 !important;
    opacity: 0;
    //display: none;
  }

  .ant-select-selection-placeholder {
    color: $fontBlack;
  }

  .ant-select-selection-item {
    padding: 0 9px;
    border: none;
    border-radius: 3px;
    line-height: 23px !important;
    background: rgba(#000, 0.05);

    &__remove {
      display: none !important;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 53px;
    background-image: linear-gradient(270deg, #FFFFFF 51%, rgba(#fff, 0) 98%);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    pointer-events: none;
  }
}

.ant-select-selection-selected-value {
  padding: 0;
}

//.ant-select ul,
//.ant-select ol {
//  min-width: 1000px;
//}

.ant-select-allow-clear .ant-select-selection .ant-select-selection-item {
  margin-right: 30px;
}

.ant-select-selection-placeholder {
  color: rgba(0, 1, 4, 0.48);
}

.ant-select-open .ant-select-selection-placeholder {
  color: rgba(0, 1, 4, 1);
}

.ant-select-selection > ul > li,
.ant-select-selection .ant-select-selection-item > ul > li {
  margin-top: 7px;
}

.ant-selection-hide-dropdown .ant-select-selection {
  border-radius: 4px !important;
}

.ant-select-selection__choice {
  padding: 0 23px 0 7px;
  border: none;
  border-radius: 4px;
  background-color: rgba(0, 1, 4, 0.04);

  .ant-select-selection__hide-in-chose {
    display: none;
  }
}

.ant-select-selection:hover {
  border-color: rgba(0, 1, 4, 0.2);
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active {
  box-shadow: none;
  border-color: rgba(0, 1, 4, 0.2);
}

.ant-select-open .ant-select-selector,
.ant-select-selector:active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-select-selection__clear:before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDcgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9Ii0iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtb3BhY2l0eT0iMC44Ij4gICAgICAgIDxnIGlkPSJzdGF0dXMtZmlsbGVkLWFsbC1mbHQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNDAuMDAwMDAwLCAtMjUuMDAwMDAwKSIgZmlsbD0iIzAwMDEwNCI+ICAgICAgICAgICAgPGcgaWQ9Imxpc3Rfb3BlbiI+ICAgICAgICAgICAgICAgIDxnPiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMzcuMDAwMDAwLCAyMi4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNiw2IEw2LDIuNSBMNiwyIEw3LDIgTDcsMi41IEw3LDYgTDEwLjUsNiBMMTEsNiBMMTEsNyBMMTAuNSw3IEw3LDcgTDcsMTAuNSBMNywxMSBMNiwxMSBMNiwxMC41IEw2LDcgTDIuNSw3IEwyLDcgTDIsNiBMMi41LDYgTDYsNiBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuNTAwMDAwLCA2LjUwMDAwMCkgcm90YXRlKDQ1LjAwMDAwMCkgdHJhbnNsYXRlKC02LjUwMDAwMCwgLTYuNTAwMDAwKSAiPjwvcGF0aD4gICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
}

.ant-select-selection__clear:hover:before {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDcgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9Ii0iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9InN0YXR1cy1maWxsZWQtYWxsLWZsdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0MC4wMDAwMDAsIC0yNS4wMDAwMDApIiBmaWxsPSIjREY1MjRFIj4gICAgICAgICAgICA8ZyBpZD0ibGlzdF9vcGVuIj4gICAgICAgICAgICAgICAgPGc+ICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIzNy4wMDAwMDAsIDIyLjAwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik02LDYgTDYsMi41IEw2LDIgTDcsMiBMNywyLjUgTDcsNiBMMTAuNSw2IEwxMSw2IEwxMSw3IEwxMC41LDcgTDcsNyBMNywxMC41IEw3LDExIEw2LDExIEw2LDEwLjUgTDYsNyBMMi41LDcgTDIsNyBMMiw2IEwyLjUsNiBMNiw2IFoiIGlkPSJDb21iaW5lZC1TaGFwZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNi41MDAwMDAsIDYuNTAwMDAwKSByb3RhdGUoNDUuMDAwMDAwKSB0cmFuc2xhdGUoLTYuNTAwMDAwLCAtNi41MDAwMDApICI+PC9wYXRoPiAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
}

.ant-selection img,
.ant-selection i,
.ant-selection .ant-select-arrow {
  display: none;
}

.ant-select-selection__clear {
  top: 1px;
  right: 1px;
  width: 29px;
  height: 29px;
  padding-right: 5px;
  padding-top: 8px;
  margin: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.ant-selection__dropdown.ant-select-dropdown {
  box-shadow: none;
  border: none;
  background: none;
  overflow: visible;
  transform: translate3d(0,0,0);
}

.ant-selection__dropdown > div {
  position: relative;
  top: -7px;
  left: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 1, 4, 0.2);
  box-shadow: 0 1px 3px 0 rgba(0, 1, 4, 0.1), 0 4px 16px 2px #fff;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}

.ant-selection__dropdown .ant-select-dropdown-menu {
  padding-top: 4px;
  padding-bottom: 2px;
}

.ant-selection__dropdown {
  .ant-select-dropdown-menu-item {
    padding: 0 11px;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    color: $fontBlack;

    @media only screen and (max-width: 1000px) {
      font-size: 15px;
    }
  }
  .ant-select-dropdown-menu-item-selected {
    font-weight: 500;
    background-color: rgba(#000104, 0.04);
  }
}

.ant-select-item {
  color: $fontBlack;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500;
  color: $fontBlack;
  background-color: rgba(#000104, 0.04);
}

.ant-selection__dropdown .ant-select-dropdown-menu-item:hover,
.ant-selection__dropdown .ant-select-dropdown-menu-item-active {
  background-color: rgba($tomeBlack, 0.04);
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input),
.ant-select-sm {
  height: 34px;

  .ant-select-selector {
    height: 32px;
    min-height: 32px;

    .ant-select-selection-search-input {
      height: 32px;
      line-height: 32px;
    }

    .ant-select-selection-placeholder {
      line-height: 32px;
    }

    .ant-select-selection-item {
      padding: 0;
      line-height: 32px;
    }
  }
  .ant-select-selection--multiple .ant-select-selection-item {
    line-height: 32px;
  }
  .ant-select-selection--single .ant-select-selection-item {
    line-height: 32px;
  }

  @media only screen and (max-width: 1000px) {
    .ant-select-selection {
      min-height: 48px;
    }
    .ant-select-selection--multiple .ant-select-selection-item {
      line-height: 46px;
    }
    .ant-select-selection--single .ant-select-selection-item {
      line-height: 46px;
    }
  }
}

.ant-select-lg {}

.ant-select-dropdown.ant-select-dropdown--multiple {
  .ant-select-dropdown-menu-item {}
  .ant-select-dropdown-menu-item-selected {
    font-weight: 500;
    background-color: rgba(#000104, 0.04);

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 14px;
      width: 13px;
      height: 9px;
      margin-top: -4px;
      background: url('./img/multipleSelectCheck.svg') no-repeat center center;
    }
  }
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-duration: 0s;
}
