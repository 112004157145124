.card-number-input {
  .has-error.has-feedback {
    .ant-input:not(.ant-input-disabled) {
      padding-right: 43px;
    }
    .ant-form-item-children-icon {
      width: 43px;
    }
  }
}

.card-input {
  .ant-input {
    height: 40px;
  }
}
