@import "src/Constants/colors";

.wrap {
  position: relative;
  display: block;
  width: 100%;
}

.lineInputWrap,
.lineInput {
  flex: 1;
  width: 100%;
}

.lineInputAmount {
  padding-right: 25px;
}

.selectWarp {
  justify-content: flex-start;
}

.productList {
  position: relative;
  display: block;
}

.product {
  position: relative;
  display: flex;
  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 0;
  }
}

.productContent {
  position: relative;
  display: block;
}

.productBottom {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 14px;
}

.productCross {
  width: 34px;
  text-align: center;
  line-height: 34px;
  color: rgba($fontBlack, 0.50);
  font-size: 16px;
}

.productBottomItem {
  flex: 1;
  position: relative;
  display: block;
}

.productBottomCount {
  max-width: 90px;
}

.productAmountCurrency {
  position: absolute;
  right: 8px;
  top: 4px;
  font-weight: 400;
  font-size: 16px;
  color: $fontBlack;
}

.productButton {
  width: 34px;
  padding-left: 1px;
  font-size: 16px;
  margin-left: 8px;
}

.removeButton {
  width: 34px;
  height: 34px;
  margin-left: 8px;
}

.nameWrap {
  position: relative;
  display: flex;
}

.nameInput {
  &:first-child {
    margin-right: 8px;
  }
}

.tabsWrap {
  display: block;
}

.tabs {
  margin-bottom: 12px;
}

.sumWarp {
  line-height: 34px;
  justify-content: flex-start;
  color: $fontBlack;
}

.sum {
  font-weight: 500;

  .currencySymbol {
    margin-top: -3px;
  }
}

.currency {
  padding-left: 6px;
  color: rgba($fontBlack, 0.56);
}

.currencySymbol {
  margin-top: -1px;
  margin-left: 4px;
}
